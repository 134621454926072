/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-delete-black': {
    width: 12,
    height: 11,
    viewBox: '0 0 12 11',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.144 10.125c.966 0 1.704-.835 1.704-1.808V2.893H.723v5.424c0 .973.739 1.808 1.705 1.808h6.716zM4.339 4.339a.362.362 0 100 .723h2.893a.362.362 0 000-.723H4.34zM.58 0A.579.579 0 000 .579v.867a.579.579 0 00.579.579h10.414a.579.579 0 00.578-.579V.58A.579.579 0 0010.993 0H.579z" _fill="#000"/>'
  }
})
