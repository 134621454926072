/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_adhesion': {
    width: 34,
    height: 34,
    viewBox: '0 0 34 34',
    data: '<circle pid="0" opacity=".1" cx="17" cy="17" r="17" _fill="#fff"/><path pid="1" d="M21.5 11H19c0-1.103-.897-2-2-2s-2 .897-2 2h-2.5a1.5 1.5 0 00-1.5 1.5v11a1.5 1.5 0 001.5 1.5h9a1.5 1.5 0 001.5-1.5v-11a1.5 1.5 0 00-1.5-1.5zM14 22.25a.748.748 0 01-.75-.75c0-.416.334-.75.75-.75s.75.334.75.75-.334.75-.75.75zm0-3a.748.748 0 01-.75-.75c0-.416.334-.75.75-.75s.75.334.75.75-.334.75-.75.75zm0-3a.748.748 0 01-.75-.75c0-.416.334-.75.75-.75s.75.334.75.75-.334.75-.75.75zm3-6c.416 0 .75.334.75.75s-.334.75-.75.75a.748.748 0 01-.75-.75c0-.416.334-.75.75-.75zm4 11.5a.25.25 0 01-.25.25h-4.5a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25v.5zm0-3a.25.25 0 01-.25.25h-4.5a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25v.5zm0-3a.25.25 0 01-.25.25h-4.5a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25v.5z" _fill="#fff"/>'
  }
})
