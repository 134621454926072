/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_comment': {
    width: 10,
    height: 10,
    viewBox: '0 0 10 10',
    data: '<path pid="0" d="M9.028 1.549a.842.842 0 00-.84-.843H1.445a.846.846 0 00-.843.843v5.059c0 .463.379.843.843.843h5.902l1.686 1.686-.004-7.588zM7.346 5.764h-5.06v-.843h5.06v.843zm0-1.264h-5.06v-.843h5.06V4.5zm0-1.265h-5.06v-.843h5.06v.843z" _fill="#000"/>'
  }
})
