
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import install from './install'
import store from './store'
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAnalytics } from 'firebase/analytics'
import { firestorePlugin } from 'vuefire'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyABddMikdBQ3XwYJVUMbHsuf1jTFuNInkI',
  authDomain: 'passhealth-14735.firebaseapp.com',
  projectId: 'passhealth-14735',
  storageBucket: 'passhealth-14735.appspot.com',
  messagingSenderId: '743872911346',
  appId: '1:743872911346:web:fab6d731a005b3790dfd6b',
  measurementId: 'G-M7BFKKTQVN'
}

// Initialize Firebase
export const db = initializeApp(firebaseConfig)
export const fireStore = getFirestore()
export const analytics = getAnalytics(db)

Vue.use(install)
Vue.use(firestorePlugin)
Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
