/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_feather_check-circle': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M18.333 9.233V10a8.333 8.333 0 11-4.941-7.617" _stroke="#08CC4A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M18.333 3.333L10 11.675l-2.5-2.5" _stroke="#08CC4A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
