/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_eye-open': {
    width: 20,
    height: 12,
    viewBox: '0 0 20 12',
    data: '<path pid="0" d="M12.997 5.999c0 1.589-1.344 2.877-3.002 2.877-1.659 0-3.003-1.288-3.003-2.877 0-1.589 1.344-2.877 3.003-2.877 1.658 0 3.002 1.288 3.002 2.877zM10 .009C8.283.016 6.503.434 4.818 1.235A13.198 13.198 0 001.29 3.804C.77 4.354.107 5.15 0 6c.013.736.802 1.644 1.29 2.196.992 1.035 2.18 1.883 3.528 2.57A12.147 12.147 0 0010 11.99c1.719-.007 3.498-.43 5.181-1.226a13.203 13.203 0 003.53-2.57C19.23 7.646 19.892 6.85 20 6c-.013-.736-.802-1.645-1.29-2.196-.992-1.035-2.18-1.883-3.529-2.57A12.217 12.217 0 0010 .01zm-.001 1.487C12.6 1.496 14.71 3.513 14.71 6c0 2.488-2.11 4.505-4.711 4.505-2.602 0-4.712-2.017-4.712-4.505 0-2.487 2.11-4.504 4.712-4.504z" _fill="#ABABAB"/>'
  }
})
