/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_eye-close': {
    width: 22,
    height: 20,
    viewBox: '0 0 22 20',
    data: '<path pid="0" d="M12.278 4.015c-.42-.046-.846-.072-1.278-.078-1.889.008-3.847.467-5.7 1.348A14.518 14.518 0 001.42 8.11C.847 8.716.117 9.592 0 10.527c.014.81.883 1.809 1.42 2.416 1.09 1.138 2.396 2.072 3.88 2.826l.152.073-1.377 2.403 1.87 1.105 10.11-17.6-1.8-1.1-1.977 3.365zm4.269 1.2l-1.374 2.38a4.785 4.785 0 011.007 2.932c0 2.736-2.32 4.955-5.181 4.955-.124 0-.244-.013-.366-.022l-.909 1.574c.42.045.844.078 1.276.083 1.89-.008 3.847-.473 5.699-1.348a14.524 14.524 0 003.882-2.826c.571-.605 1.301-1.481 1.419-2.416-.014-.81-.883-1.809-1.42-2.416C19.49 6.973 18.183 6.04 16.7 5.285c-.05-.025-.102-.046-.152-.07zm-5.548.357c.125 0 .25.005.373.014l-1.065 1.843c-1.494.303-2.616 1.575-2.616 3.097 0 .382.07.748.2 1.087v.002l-1.067 1.849a4.79 4.79 0 01-1.007-2.937c0-2.736 2.32-4.955 5.182-4.955zm3.097 3.887l-2.4 4.158c1.487-.309 2.6-1.575 2.6-3.091 0-.376-.074-.733-.2-1.067z" _fill="#ABABAB"/>'
  }
})
