/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_delete-red': {
    width: 14,
    height: 15,
    viewBox: '0 0 14 15',
    data: '<path pid="0" d="M9.16 2.375h3.563a.594.594 0 010 1.188h-.657l-.893 8.046a2.969 2.969 0 01-2.95 2.641H5.349a2.969 2.969 0 01-2.951-2.641l-.894-8.046H.848a.594.594 0 010-1.188h3.563a2.375 2.375 0 014.75 0zM6.787 1.187a1.187 1.187 0 00-1.188 1.188h2.375a1.188 1.188 0 00-1.187-1.188zm-1.782 4.75v4.75a.594.594 0 101.188 0v-4.75a.594.594 0 10-1.188 0zm2.97-.593a.594.594 0 00-.595.593v4.75a.594.594 0 101.188 0v-4.75a.594.594 0 00-.594-.593z" _fill="#C43A2B"/>'
  }
})
