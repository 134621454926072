/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_admin': {
    width: 34,
    height: 34,
    viewBox: '0 0 34 34',
    data: '<circle pid="0" opacity=".1" cx="17" cy="17" r="17" _fill="#fff"/><path pid="1" d="M23.476 15.987l-1.49-.445a5.181 5.181 0 00-.422-1.035l.73-1.365a.27.27 0 00-.05-.32l-1.062-1.066a.271.271 0 00-.32-.05l-1.355.725a5.164 5.164 0 00-1.05-.444l-.444-1.472a.271.271 0 00-.262-.182H16.25a.272.272 0 00-.258.191l-.444 1.467a5.17 5.17 0 00-1.058.444l-1.333-.72a.272.272 0 00-.32.05l-1.08 1.053a.27.27 0 00-.05.32l.72 1.333a5.17 5.17 0 00-.444 1.053l-1.47.445a.271.271 0 00-.192.258v1.502a.272.272 0 00.191.258l1.48.444c.11.36.26.708.445 1.036l-.73 1.395a.27.27 0 00.05.32l1.062 1.062a.27.27 0 00.32.05l1.373-.734c.325.175.668.315 1.022.418l.445 1.497a.27.27 0 00.258.192h1.502a.271.271 0 00.258-.192l.444-1.502a5.165 5.165 0 001.013-.418l1.383.738a.27.27 0 00.32-.049l1.062-1.062a.27.27 0 00.049-.32l-.738-1.378a5.17 5.17 0 00.422-1.017l1.498-.445a.271.271 0 00.191-.258V16.25a.271.271 0 00-.164-.262zM17 19.444a2.444 2.444 0 110-4.889 2.444 2.444 0 010 4.89z" _fill="#fff"/>'
  }
})
