/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_sexual_gender': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" d="M26.65 25.166c1.09 1.091 2.114 1.091 3.069 0l9.207-9.105c.886-1.023.886-2.046 0-3.069-1.023-1.023-2.046-1.023-3.07 0L26.65 3.785c1.09-.955 1.09-1.944 0-2.967-.955-1.09-1.944-1.09-2.967 0l-9.207 8.9c-1.023 1.024-1.023 2.047 0 3.07.954.954 1.977.954 3.069 0l3.069 3.069-6.138 6.036c-1.023-.887-2.046-.887-3.07 0L.768 32.634c-1.023 1.023-1.023 2.046 0 3.07l2.762 3.478c1.092 1.09 2.115 1.09 3.07 0L17.34 28.44c.41-.41.614-.904.614-1.484s-.205-1.074-.614-1.483l6.036-6.138 3.069 3.07c-.818.817-.75 1.738.205 2.761zm-2.967-12.174l-3.07-3.069 3.07-3.069 9.105 9.207-3.07 3.07-3.068-3.07-2.967-3.069z" _fill="#13A8C0"/>'
  }
})
