/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_quiz': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<path pid="0" d="M17.875 4.813H16.5v-.688a1.376 1.376 0 00-1.375-1.375h-8.25A1.376 1.376 0 005.5 4.125v.688H4.125A1.376 1.376 0 002.75 6.188V8.25A2.753 2.753 0 005.5 11h.221a5.616 5.616 0 004.591 4.08v2.795H6.876v1.375h8.25v-1.375h-3.438v-2.797A5.476 5.476 0 0016.322 11h.179a2.753 2.753 0 002.75-2.75V6.187a1.376 1.376 0 00-1.375-1.375zM5.5 9.625A1.376 1.376 0 014.125 8.25V6.187H5.5v3.438zM17.875 8.25A1.376 1.376 0 0116.5 9.625V6.187h1.375V8.25z" _fill="#0130D9"/>'
  }
})
