/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_more-info': {
    width: 19,
    height: 19,
    viewBox: '0 0 19 19',
    data: '<circle pid="0" cx="9.5" cy="9.5" r="9.5" _fill="#E9E9E9"/><path pid="1" d="M10.289 13H8.776V7.15h1.513V13zM8.653 4.803c0-.245.074-.452.221-.621.153-.17.376-.254.67-.254.295 0 .519.085.671.254a.894.894 0 01.23.621c0 .246-.077.45-.23.614-.152.163-.376.245-.67.245-.295 0-.518-.082-.671-.245a.883.883 0 01-.22-.614z" _fill="#606060"/>'
  }
})
