/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_select-right-answer': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.867 8.276c.353.295.401.82.107 1.174l-4.167 5a.833.833 0 01-1.23.056l-2.5-2.5a.833.833 0 111.18-1.179l1.854 1.855 3.582-4.299a.833.833 0 011.174-.107z" _fill="#00A958"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M11 3.5a7.5 7.5 0 100 15 7.5 7.5 0 000-15zM1.834 11a9.167 9.167 0 1118.333 0 9.167 9.167 0 01-18.334 0z" _fill="#00A958"/>'
  }
})
