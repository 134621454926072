/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_search': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.326 12.899l5.38 5.38a1.009 1.009 0 11-1.427 1.426l-5.38-5.38a8 8 0 111.426-1.426zM8 14A6 6 0 108 2a6 6 0 000 12z" _fill="#727272"/>'
  }
})
