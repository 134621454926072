/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_add-image': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M23.438 5A4.063 4.063 0 0127.5 9.063v14.374a4.064 4.064 0 01-4.063 4.063H9.063A4.064 4.064 0 015 23.437v-7.81a8.127 8.127 0 001.875.528v7.282c0 .26.045.51.129.743l7.279-7.126a2.812 2.812 0 013.775-.145l.16.145 7.277 7.127c.084-.232.13-.482.13-.744V9.063a2.188 2.188 0 00-2.188-2.187h-7.282A8.07 8.07 0 0015.627 5h7.81zm-7.74 13.305l-.104.087-7.259 7.11c.227.08.472.123.727.123h14.376c.253 0 .498-.044.725-.124l-7.257-7.107a.937.937 0 00-1.207-.089zm4.617-8.93a2.815 2.815 0 110 5.63 2.815 2.815 0 010-5.63zM8.125 1.25a6.875 6.875 0 110 13.75 6.875 6.875 0 010-13.75zm12.19 10a.939.939 0 100 1.878.939.939 0 000-1.878zm-12.19-7.5l-.113.009a.625.625 0 00-.502.502l-.01.114V7.5H4.372l-.112.01a.625.625 0 00-.502.502l-.01.113.01.113a.625.625 0 00.502.502l.112.01H7.5v3.129l.01.112a.625.625 0 00.502.503l.113.011.113-.011a.626.626 0 00.502-.503l.01-.112V8.75h3.131l.113-.01a.625.625 0 00.502-.502l.01-.113-.01-.113a.625.625 0 00-.504-.502l-.112-.01H8.75V4.375l-.01-.112a.625.625 0 00-.502-.504l-.113-.009z" _fill="#263238"/>'
  }
})
