/*
App
Created at 09/04/2021 11:44
Author: Khaliq ALI
 */
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    token: '',
    user: {},
    quizId: null,
    newQuiz: true,
    order: 0
  },
  mutations: {
    UPDATE_TOKEN (state, token) {
      state.token = token
    },
    UPDATE_USER (state, user) {
      state.user = user
    },
    UPDATE_QUIZ_ID (state, id) {
      state.quizId = id
    },
    UPDATE_NEW_QUIZ (state, news) {
      state.newQuiz = news
    },
    UPDATE_ORDER (state, order) {
      state.order = order
    },
    LOGOUT (state) {
      state.token = ''
      state.user = {}
      state.quizId = null
      state.newQuiz = true
      state.order = 0
    }
  },
  actions: {
    CREATE_TOKEN ({ commit }, toke) {
      commit('UPDATE_TOKEN', toke)
    },
    CREATE_USER ({ commit }, use) {
      commit('UPDATE_USER', use)
    },
    CREATE_QUIZ_ID ({ commit }, quiz) {
      commit('UPDATE_QUIZ_ID', quiz)
    },
    CREATE_NEW_QUIZ ({ commit }, neuf) {
      commit('UPDATE_NEW_QUIZ', neuf)
    },
    CREATE_ORDER ({ commit }, ordre) {
      commit('UPDATE_ORDER', ordre)
    },
    IS_LOGOUT ({ commit }, dec) {
      commit('LOGOUT', dec)
    }
  },
  getters: {
    loginToken: state => {
      return state.token
    },
    userInfos: state => {
      return state.user
    },
    isQuizId: state => {
      return state.quizId
    },
    isNewQuiz: state => {
      return state.newQuiz
    },
    questionOrder: state => {
      return state.order
    }
  }
})
