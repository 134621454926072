/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_briefcase': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M15.817 5.235c-.637-.705-1.702-1.057-3.247-1.057h-.18v-.03c0-1.26 0-2.82-2.82-2.82H8.43c-2.82 0-2.82 1.567-2.82 2.82v.037h-.18c-1.553 0-2.61.353-3.248 1.058-.742.825-.72 1.935-.645 2.692l.008.053.052.55c.014.15.095.285.221.368.182.118.447.288.612.379a12.874 12.874 0 004.455 1.597c.067.705.375 1.53 2.017 1.53 1.643 0 1.965-.817 2.018-1.545 1.53-.247 3.007-.78 4.342-1.56.045-.022.075-.044.113-.067.282-.16.574-.354.843-.546a.491.491 0 00.2-.346l.007-.053.037-.352c.008-.045.008-.083.015-.135.06-.758.045-1.793-.66-2.573zm-6 5.138c0 .795 0 .915-.922.915-.923 0-.923-.143-.923-.908v-.945h1.845v.938zM6.682 4.178v-.03c0-1.275 0-1.748 1.748-1.748h1.14c1.747 0 1.747.48 1.747 1.748v.037H6.682v-.007z" _fill="#B9B9B9"/><path pid="1" d="M15.453 10.396a.51.51 0 01.726.504l-.25 2.742c-.157 1.5-.772 3.03-4.072 3.03H6.142c-3.3 0-3.915-1.53-4.072-3.022l-.236-2.593a.51.51 0 01.716-.508c.862.386 2.236.973 3.14 1.22a.601.601 0 01.374.316c.468.934 1.443 1.43 2.838 1.43 1.382 0 2.37-.515 2.839-1.453a.603.603 0 01.373-.315c.964-.266 2.433-.925 3.34-1.35z" _fill="#B9B9B9"/>'
  }
})
