/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_article': {
    width: 34,
    height: 34,
    viewBox: '0 0 34 34',
    data: '<circle pid="0" opacity=".1" cx="17" cy="17" r="17" _fill="#fff"/><path pid="1" d="M21.667 11h-9.334C11.6 11 11 11.6 11 12.333v9.334C11 22.4 11.6 23 12.333 23h9.334C22.4 23 23 22.4 23 21.667v-9.334C23 11.6 22.4 11 21.667 11zm-4 9.333h-3.334a.669.669 0 01-.666-.666c0-.367.3-.667.666-.667h3.334c.366 0 .666.3.666.667 0 .366-.3.666-.666.666zm2-2.666h-5.334a.669.669 0 01-.666-.667c0-.367.3-.667.666-.667h5.334c.366 0 .666.3.666.667 0 .367-.3.667-.666.667zm0-2.667h-5.334a.669.669 0 01-.666-.667c0-.366.3-.666.666-.666h5.334c.366 0 .666.3.666.666 0 .367-.3.667-.666.667z" _fill="#fff"/>'
  }
})
