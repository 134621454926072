/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_transgender': {
    width: 31,
    height: 40,
    viewBox: '0 0 31 40',
    data: '<path pid="0" d="M29.45 0h-6.254c-.847 0-1.267 1.008-.673 1.602l1.338 1.32-6.389 6.305A11.457 11.457 0 0011.4 7.5C5.106 7.5 0 12.54 0 18.75c0 5.352 3.792 9.836 8.867 10.969v2.156h-2.85a.947.947 0 00-.95.938v3.124c0 .516.427.938.95.938h2.85v2.188c0 .515.427.937.95.937h3.166c.523 0 .95-.422.95-.938v-2.187h2.85c.523 0 .95-.422.95-.938v-3.124a.947.947 0 00-.95-.938h-2.85v-2.156c5.075-1.14 8.867-5.617 8.867-10.969 0-2.203-.641-4.258-1.75-5.992l6.39-6.305 1.337 1.32c.602.594 1.623.172 1.623-.664V.938A.947.947 0 0029.45 0zM11.4 25c-3.491 0-6.333-2.805-6.333-6.25S7.909 12.5 11.4 12.5s6.333 2.805 6.333 6.25S14.891 25 11.4 25z" _fill="#13A8C0"/>'
  }
})
