/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_quizzz': {
    width: 34,
    height: 34,
    viewBox: '0 0 34 34',
    data: '<circle pid="0" opacity=".1" cx="17" cy="17" r="17" _fill="#fff"/><path pid="1" d="M21 12.375V11h-8v1.375h-2.667v1.958c0 1.684 1.26 3.066 2.883 3.288a3.998 3.998 0 003.117 2.652V21A1.333 1.333 0 0115 22.333h-.667v1.334h5.334v-1.334H19A1.333 1.333 0 0117.667 21v-.727a3.998 3.998 0 003.117-2.652c1.623-.222 2.883-1.604 2.883-3.288v-1.958H21zm-9.333 1.958v-.625H13v2.51a2.003 2.003 0 01-1.333-1.885zm10.666 0A2.004 2.004 0 0121 16.22v-2.511h1.333v.625z" _fill="#fff"/>'
  }
})
