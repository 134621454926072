/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_dropdown': {
    width: 20,
    height: 12,
    viewBox: '0 0 20 12',
    data: '<path pid="0" d="M1 1l9 9 9-9" _stroke="#606060" stroke-width="2"/>'
  }
})
