/*
App
Created at 09/04/2021 11:44
Author: Khaliq ALI
 */
import './assets/style/css/tailwind.css'
import './assets/style/css/style.css'
import './assets/style/sass/main.scss'
import './assets/style/sass/mixin.scss'
import '@/components/icons'
import SvgIcon from 'vue-svgicon'
import x5GMaps from 'x5-gmaps'
import Vueditor from 'vueditor'
import 'vueditor/dist/style/vueditor.min.css'
import ImageUploader from 'vue-image-upload-resize'

export default {
  install (Vue, options) {
    Vue.use(SvgIcon, {
      tagName: 'svgIcon'
    })
    // Vue.use(x5GMaps, 'AIzaSyCCCI9A3FxyT27m_NFpRwrHMcQIq1P1Ol8')
    // Vue.use(x5GMaps, { key: 'AIzaSyDwgeYC36l4W8BZlV2kOqGl671dYXGRK_Q', libraries: ['places'] })
    Vue.use(x5GMaps, { key: 'AIzaSyD9FNBP8ymQDNY759TWEbYpWlo_EihkkUo', libraries: ['places'] })
    const config = {
      toolbar: [
        'bold', 'italic', 'underline', 'divider', 'elements', 'fontSize',
        'justifyLeft', 'justifyCenter', 'justifyFull', 'indent', 'outdent',
        'insertOrderedList', 'insertUnorderedList', 'fullscreen', '|', 'picture', 'tables', '|', 'switchView',
        'undo', 'redo'
      ],
      fontName: [
        { val: 'arial black' },
        { val: 'times new roman' },
        { val: 'Courier New' }
      ],
      fontSize: [
        '12px', '14px', '16px', '18px', '20px', '24px', '28px', '32px', '36px'
      ],
      uploadUrl: '',
      id: '',
      classList: []
    }
    Vue.use(Vueditor, config)
    Vue.use(ImageUploader)
  }
}
