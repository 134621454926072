/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_delete_article': {
    width: 46,
    height: 46,
    viewBox: '0 0 46 46',
    data: '<rect pid="0" x=".359" width="45.641" height="45.641" rx="8.298" _fill="#FF2323" fill-opacity=".15"/><path pid="1" d="M19.614 15.688v-2.14a.713.713 0 01.713-.713h5.705a.713.713 0 01.713.713v2.14h5.705a.713.713 0 110 1.427H13.91a.713.713 0 010-1.426h5.705zm1.426 0h4.279v-1.426H21.04v1.426zm-4.992 17.116a.713.713 0 01-.713-.713V17.115h15.689V32.09a.713.713 0 01-.713.713H16.048zm4.992-4.279a.714.714 0 00.713-.713V20.68a.713.713 0 10-1.426 0v7.132a.713.713 0 00.713.713zm4.279 0a.713.713 0 00.713-.713V20.68a.713.713 0 00-1.426 0v7.132a.713.713 0 00.713.713z" _fill="#FF2323"/>'
  }
})
