/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_mail': {
    width: 24,
    height: 20,
    viewBox: '0 0 24 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.055 1.454A3.6 3.6 0 013.6.4h16.8A3.6 3.6 0 0124 4v12a3.6 3.6 0 01-3.6 3.6H3.6A3.6 3.6 0 010 16V5.216 4a3.6 3.6 0 011.055-2.546zM2.4 7.442V16a1.2 1.2 0 001.2 1.2h16.8a1.2 1.2 0 001.2-1.2V7.442l-7.602 5.068a3.6 3.6 0 01-3.996 0L2.4 7.442zm19.2-2.884l-8.934 5.955a1.2 1.2 0 01-1.332 0L2.4 4.558V4a1.2 1.2 0 011.2-1.2h16.8A1.2 1.2 0 0121.6 4v.558z" _fill="#000"/>'
  }
})
