/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_close': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M16 2.33L14.47.8 8.4 6.87 2.33.8.8 2.33 6.87 8.4.8 14.47 2.33 16 8.4 9.93 14.47 16 16 14.47 9.93 8.4 16 2.33z" _fill="#000814"/>'
  }
})
