/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_forum': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<path pid="0" d="M16.069 7.123a.677.677 0 00-.192 0 2.634 2.634 0 01-2.549-2.64 2.65 2.65 0 012.65-2.65 2.65 2.65 0 012.649 2.65 2.649 2.649 0 01-2.558 2.64zM19.058 13.475c-1.027.688-2.466.944-3.795.77a5.986 5.986 0 00.54-2.466c0-.916-.201-1.787-.586-2.548 1.356-.184 2.796.073 3.832.76 1.448.954 1.448 2.521.009 3.484zM5.903 7.123a.677.677 0 01.193 0 2.634 2.634 0 002.548-2.64 2.645 2.645 0 10-5.289 0 2.634 2.634 0 002.548 2.64zM6.004 11.78c0 .888.193 1.732.54 2.492-1.292.138-2.64-.137-3.63-.788-1.447-.962-1.447-2.53 0-3.492.982-.66 2.366-.926 3.668-.78a5.828 5.828 0 00-.578 2.567zM11.11 14.547a1.031 1.031 0 00-.238 0 3.14 3.14 0 01-3.035-3.144A3.161 3.161 0 0111 8.25a3.153 3.153 0 013.153 3.153 3.147 3.147 0 01-3.043 3.144zM8.13 16.445c-1.383.926-1.383 2.447 0 3.364 1.578 1.054 4.163 1.054 5.74 0 1.383-.926 1.383-2.447 0-3.364-1.568-1.054-4.153-1.054-5.74 0z" _fill="#EE3147"/>'
  }
})
