/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_select-not-answer': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16.001A8 8 0 0112 20z" _fill="#B9B9B9"/><path pid="1" d="M14.71 9.29a.999.999 0 00-1.42 0L12 10.59l-1.29-1.3a1.004 1.004 0 10-1.42 1.42l1.3 1.29-1.3 1.29a1 1 0 00.325 1.639 1 1 0 001.095-.219l1.29-1.3 1.29 1.3a1.001 1.001 0 001.639-.325 1 1 0 00-.22-1.095L13.41 12l1.3-1.29a.999.999 0 000-1.42z" _fill="#B9B9B9"/>'
  }
})
