/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_ps-justice': {
    width: 60,
    height: 61,
    viewBox: '0 0 60 61',
    data: '<path pid="0" d="M40.065 37.935c1.64 1.64 3.178 1.64 4.613 0l13.838-13.684c1.332-1.538 1.332-3.076 0-4.613-1.538-1.538-3.075-1.538-4.613 0L40.065 5.8c1.64-1.435 1.64-2.922 0-4.46-1.435-1.64-2.921-1.64-4.459 0L21.768 14.719c-1.538 1.537-1.538 3.075 0 4.612 1.435 1.436 2.973 1.436 4.613 0l4.612 4.613-9.225 9.072c-1.538-1.333-3.075-1.333-4.613 0L1.165 49.159c-1.538 1.538-1.538 3.075 0 4.613L5.314 59c1.64 1.64 3.178 1.64 4.614 0l16.144-16.145a3.037 3.037 0 00.923-2.23c0-.87-.308-1.614-.923-2.229l9.072-9.225 4.612 4.613c-1.23 1.23-1.127 2.613.308 4.151zm-4.459-18.297l-4.613-4.613 4.613-4.613L49.29 24.252l-4.612 4.612-4.613-4.612-4.459-4.613z" _fill="#F3F4F5"/>'
  }
})
