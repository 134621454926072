/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_feather-trash': {
    width: 20,
    height: 22,
    viewBox: '0 0 20 22',
    data: '<path pid="0" d="M1 5h18M6 5V3a2 2 0 012-2h4a2 2 0 012 2v2m3 0v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5h14zM8 10v6M12 10v6" _stroke="#F44336" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
