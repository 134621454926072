/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_select-answer': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.218 9.029a.91.91 0 01.116 1.28l-4.545 5.455a.91.91 0 01-1.341.06L7.72 13.098a.91.91 0 011.286-1.285l2.023 2.023 3.909-4.69a.91.91 0 011.28-.116z" _fill="#B9B9B9"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M12 3.818a8.182 8.182 0 100 16.364 8.182 8.182 0 000-16.364zM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12z" _fill="#B9B9B9"/>'
  }
})
