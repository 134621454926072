/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_forum_menu': {
    width: 34,
    height: 34,
    viewBox: '0 0 34 34',
    data: '<circle pid="0" opacity=".1" cx="17" cy="17" r="17" _fill="#fff"/><path pid="1" d="M11 21.5h1.5v3.06l3.826-3.06H20c.827 0 1.5-.673 1.5-1.5v-6c0-.827-.673-1.5-1.5-1.5h-9c-.827 0-1.5.673-1.5 1.5v6c0 .827.673 1.5 1.5 1.5z" _fill="#fff"/><path pid="2" d="M23 9.5h-9c-.827 0-1.5.673-1.5 1.5h9c.827 0 1.5.673 1.5 1.5v6c.827 0 1.5-.673 1.5-1.5v-6c0-.827-.673-1.5-1.5-1.5z" _fill="#fff"/>'
  }
})
