/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_arrow-right': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.55 2.5L7 4.05 14.95 12 7 19.95l1.55 1.55L18 12 8.55 2.5z" _fill="#000"/>'
  }
})
