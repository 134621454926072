/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_aid': {
    width: 35,
    height: 40,
    viewBox: '0 0 35 40',
    data: '<path pid="0" d="M23.374 20.573l3.354-4.096a9.61 9.61 0 001.488-9.66A10.552 10.552 0 0018.417.19 10.552 10.552 0 008.62 6.816a10.266 10.266 0 001.232 9.859l2.703 3.654-.028.02L.794 32.07l7.144 7.138L17.783 27.4l8.732 11.807 7.144-7.137-10.285-11.497zM13.662 9.7a6.74 6.74 0 019.485-.025c-.55.663-.622.666-4.998 5.058-4.367-4.888-4.188-4.665-4.487-5.033z" _fill="#13A8C0"/>'
  }
})
