/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_allUser': {
    width: 50,
    height: 50,
    viewBox: '0 0 50 50',
    data: '<circle pid="0" opacity=".1" cx="25" cy="25" r="25" _fill="#21ADC4"/><path pid="1" d="M25.5 11C17.505 11 11 17.505 11 25.5S17.505 40 25.5 40 40 33.495 40 25.5 33.495 11 25.5 11zm0 1.813c6.996 0 12.688 5.691 12.688 12.687 0 2.915-.998 5.595-2.657 7.74a23.412 23.412 0 00-20.062 0 12.609 12.609 0 01-2.656-7.74c0-6.996 5.691-12.688 12.687-12.688zm-8.76 21.843a21.583 21.583 0 0117.52 0 12.633 12.633 0 01-8.76 3.532c-3.397 0-6.48-1.35-8.76-3.532z" _fill="#21ADC4"/><path pid="2" d="M25.5 29.125a5.443 5.443 0 005.438-5.438A5.443 5.443 0 0025.5 18.25a5.443 5.443 0 00-5.438 5.438 5.443 5.443 0 005.438 5.437zm0-9.063c2 0 3.625 1.626 3.625 3.625 0 2-1.626 3.625-3.625 3.625-2 0-3.625-1.625-3.625-3.625 0-1.999 1.626-3.625 3.625-3.625z" _fill="#21ADC4"/>'
  }
})
