/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_lock-closed': {
    width: 23,
    height: 24,
    viewBox: '0 0 23 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.302 1.739a5.938 5.938 0 0110.136 4.199V9.5h1.187a3.563 3.563 0 013.563 3.563v7.124a3.563 3.563 0 01-3.563 3.563H4.375a3.562 3.562 0 01-3.563-3.563v-7.125A3.563 3.563 0 014.376 9.5h1.188V5.937c0-1.574.625-3.084 1.739-4.198zM7.938 9.5h7.125V5.937a3.562 3.562 0 10-7.126 0V9.5zm-3.563 2.375a1.187 1.187 0 00-1.188 1.188v7.124a1.188 1.188 0 001.188 1.188h14.25a1.188 1.188 0 001.188-1.188v-7.125a1.188 1.188 0 00-1.188-1.187H4.375zM11.5 14.25c.656 0 1.188.532 1.188 1.188v2.374a1.188 1.188 0 01-2.376 0v-2.375c0-.655.532-1.187 1.188-1.187z" _fill="#606060"/>'
  }
})
