/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_point-ssr': {
    width: 34,
    height: 34,
    viewBox: '0 0 34 34',
    data: '<circle pid="0" opacity=".1" cx="17" cy="17" r="17" _fill="#fff"/><path pid="1" d="M17 23a19.839 19.839 0 01-2.333-2.354c-1.067-1.274-2.334-3.171-2.334-4.98a4.667 4.667 0 119.334 0c0 1.809-1.267 3.706-2.334 4.98A19.846 19.846 0 0117 23zm0-9.333a2 2 0 100 3.999 2 2 0 000-4z" _fill="#fff"/>'
  }
})
