/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_solid_transgender': {
    width: 52,
    height: 69,
    viewBox: '0 0 52 69',
    data: '<path pid="0" d="M50.269.647H39.64c-1.44 0-2.152 1.713-1.143 2.722l2.273 2.244-10.857 10.714a19.469 19.469 0 00-10.319-2.934C8.898 13.393.221 21.956.221 32.51c0 9.095 6.444 16.716 15.068 18.64v3.665h-4.843c-.888 0-1.615.717-1.615 1.593v5.311c0 .877.727 1.593 1.615 1.593h4.843v3.718c0 .876.726 1.593 1.615 1.593h5.381c.888 0 1.614-.717 1.614-1.593v-3.718h4.844c.888 0 1.614-.717 1.614-1.593v-5.31c0-.877-.726-1.594-1.614-1.594h-4.844v-3.664c8.624-1.938 15.069-9.546 15.069-18.64 0-3.745-1.09-7.236-2.974-10.184l10.858-10.714 2.273 2.244c1.023 1.009 2.758.292 2.758-1.129V2.24c0-.876-.726-1.593-1.614-1.593zM19.594 43.133c-5.933 0-10.763-4.767-10.763-10.622 0-5.855 4.83-10.621 10.763-10.621s10.763 4.766 10.763 10.621-4.83 10.622-10.763 10.622z" _fill="#F3F4F5"/>'
  }
})
