/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_phone': {
    width: 12,
    height: 11,
    viewBox: '0 0 12 11',
    data: '<path pid="0" d="M4.59 6.316a6.606 6.606 0 002.4 1.553l1.223-.973a.217.217 0 01.246 0l2.27 1.463a.648.648 0 01.107 1.03l-1.063 1.052a1.297 1.297 0 01-1.177.346A11.379 11.379 0 013.034 7.87 10.958 10.958 0 01.028 2.41 1.226 1.226 0 01.385 1.26L1.487.196A.649.649 0 012.5.3L4.01 2.54a.204.204 0 010 .244l-.995 1.197A6.405 6.405 0 004.59 6.316z" _fill="#000"/>'
  }
})
