/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_update_article': {
    width: 46,
    height: 46,
    viewBox: '0 0 46 46',
    data: '<rect pid="0" width="45.641" height="45.641" rx="8.298" _fill="#000" fill-opacity=".1"/><path pid="1" d="M30.89 18.787a2.853 2.853 0 00-4.035-4.034l-1 1 4.033 4.035 1.001-1zm-2.01 2.01l-8.526 8.525a3.564 3.564 0 01-1.657.938l-3.267.817a.713.713 0 01-.865-.865l.817-3.267c.157-.627.48-1.2.938-1.657l8.525-8.525 4.034 4.034z" _fill="#979797"/>'
  }
})
