/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_down': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M13.131 4.688H2.87c-.308 0-.48.325-.29.546l5.132 5.95c.147.17.43.17.578 0l5.131-5.95c.19-.221.019-.546-.289-.546z" _fill="#595B62"/>'
  }
})
