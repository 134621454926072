/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_page-right': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.71 23.812a.906.906 0 00.674-.31l7.635-8.484a1.144 1.144 0 000-1.5l-7.635-8.483a.887.887 0 00-1.35 0 1.144 1.144 0 000 1.5l6.96 7.733-6.96 7.734a1.144 1.144 0 000 1.5c.187.206.43.31.675.31z" _fill="#7F8896"/>'
  }
})
