/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_hospital': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M16.5 15.938h-15a.567.567 0 00-.563.562c0 .308.255.563.563.563h15a.567.567 0 00.563-.563.567.567 0 00-.563-.563z" _fill="#B9B9B9"/><path pid="1" d="M12.75 1.5h-7.5c-2.25 0-3 1.342-3 3v12h4.5v-4.545c0-.39.315-.705.705-.705h3.098c.382 0 .704.315.704.705V16.5h4.5v-12c-.007-1.658-.757-3-3.007-3zm-1.875 5.438H9.562V8.25A.567.567 0 019 8.813a.567.567 0 01-.563-.563V6.937H7.125a.567.567 0 01-.563-.562c0-.308.255-.563.563-.563h1.313V4.5c0-.308.255-.563.562-.563.307 0 .563.256.563.563v1.313h1.312c.307 0 .563.255.563.562a.567.567 0 01-.563.563z" _fill="#B9B9B9"/>'
  }
})
