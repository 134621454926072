/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_condom': {
    width: 62,
    height: 62,
    viewBox: '0 0 62 62',
    data: '<path pid="0" d="M61.54 22.194c-1.227-4.866-4.876-8.182-10.012-9.096-6.683-1.193-13.903.755-19.635 2.74-2.061-3.304-3.529-6.556-4.245-8.273a9.791 9.791 0 00-1.776-2.832C21.441-.125 17.165.726 15.009 1.665c-1.98.864-3.64 2.445-4.67 4.454-1.58 3.082-1.28 6.289-.75 8.435.304 1.236.897 2.42 1.812 3.616a23.184 23.184 0 013.559 6.696c-4.085 3.111-7.531 6.6-10 10.125C1.076 40.537-.35 46.178.946 50.874c.989 3.582 3.457 6.347 7.14 7.998a28.658 28.658 0 0011.844 2.525c11.891 0 22.278-6.978 26.958-10.689 13.42-10.643 16.369-21.707 14.651-28.514zM41.067 43.4c-9.813 7.487-19.57 10.277-26.1 7.461-2.658-1.145-4.372-2.918-4.954-5.127-.754-2.854.367-6.37 3.241-10.17a33.968 33.968 0 012.96-3.377c.148 5.815-1.568 10.238-1.59 10.297a1.215 1.215 0 002.254.904c.144-.36 3.483-8.88.664-18.405v-.007l-.009-.022c-.007-.026-.01-.05-.02-.075-.003-.012-.012-.021-.016-.033a25.591 25.591 0 00-4.165-8.15c-.708-.927-1.161-1.818-1.385-2.724-.431-1.744-.685-4.33.553-6.745.775-1.51 2.011-2.695 3.48-3.335.82-.359 1.637-.537 2.448-.537 1.926 0 3.817 1.008 5.649 3.016.538.59.985 1.307 1.328 2.13.763 1.825 2.346 5.313 4.57 8.836.006.01.006.023.012.034.07.11.141.211.21.32.06.092.113.183.174.275l.049.064c4.315 6.574 8.977 9.99 13.623 9.99.676 0 1.351-.072 2.024-.216a1.215 1.215 0 00-.51-2.375c-3.785.811-7.255-1.558-10.067-4.713 4.332-1.212 8.338-1.443 11.672-.658 3.562.841 5.63 2.496 6.147 4.92 1.012 4.745-3.794 11.975-12.242 18.422z" _fill="#F3F4F5"/>'
  }
})
