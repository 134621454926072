/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_location': {
    width: 9,
    height: 12,
    viewBox: '0 0 9 12',
    data: '<path pid="0" d="M4.492 0C2.192 0 .33 1.86.33 4.162c0 3.121 4.162 7.729 4.162 7.729s4.162-4.608 4.162-7.73C8.654 1.862 6.793 0 4.492 0zm0 5.648a1.486 1.486 0 110-2.973 1.486 1.486 0 010 2.973z" _fill="#000"/>'
  }
})
