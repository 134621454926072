/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_user': {
    width: 29,
    height: 29,
    viewBox: '0 0 29 29',
    data: '<path pid="0" d="M14.5 0C6.505 0 0 6.505 0 14.5S6.505 29 14.5 29 29 22.495 29 14.5 22.495 0 14.5 0zm0 1.813c6.996 0 12.688 5.691 12.688 12.687 0 2.915-.998 5.595-2.657 7.74a23.412 23.412 0 00-20.062 0 12.609 12.609 0 01-2.656-7.74c0-6.996 5.691-12.687 12.687-12.687zM5.74 23.656a21.583 21.583 0 0117.52 0 12.633 12.633 0 01-8.76 3.532c-3.397 0-6.48-1.35-8.76-3.532z" _fill="#21ADC4"/><path pid="1" d="M14.5 18.125a5.443 5.443 0 005.438-5.438A5.443 5.443 0 0014.5 7.25a5.443 5.443 0 00-5.438 5.438 5.443 5.443 0 005.438 5.437zm0-9.062c2 0 3.625 1.625 3.625 3.624 0 2-1.626 3.626-3.625 3.626-2 0-3.625-1.626-3.625-3.625 0-2 1.626-3.625 3.625-3.625z" _fill="#21ADC4"/>'
  }
})
