/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_page-left': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18.767 23.812a.906.906 0 01-.674-.31l-7.635-8.484a1.144 1.144 0 010-1.5l7.635-8.483a.888.888 0 011.349 0 1.144 1.144 0 010 1.5l-6.96 7.733 6.96 7.734a1.144 1.144 0 010 1.5.906.906 0 01-.675.31z" _fill="#7F8896"/>'
  }
})
