/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_dashboard': {
    width: 34,
    height: 34,
    viewBox: '0 0 34 34',
    data: '<circle pid="0" opacity=".1" cx="17" cy="17" r="17" _fill="#fff"/><path pid="1" d="M11.667 17.667h4a.667.667 0 00.666-.667v-5.333a.667.667 0 00-.666-.667h-4a.666.666 0 00-.667.667V17a.666.666 0 00.667.667zM11 22.333a.666.666 0 00.667.667h4a.667.667 0 00.666-.667v-2.666a.667.667 0 00-.666-.667h-4a.666.666 0 00-.667.667v2.666zm6.667 0a.667.667 0 00.666.667h4a.666.666 0 00.667-.667v-4.666a.666.666 0 00-.667-.667h-4a.667.667 0 00-.666.667v4.666zm.666-6.666h4A.667.667 0 0023 15v-3.333a.666.666 0 00-.667-.667h-4a.667.667 0 00-.666.667V15a.667.667 0 00.666.667z" _fill="#fff"/>'
  }
})
