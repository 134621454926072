/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_sida': {
    width: 55,
    height: 63,
    viewBox: '0 0 55 63',
    data: '<path pid="0" d="M36.978 32.664l5.177-6.322a14.834 14.834 0 002.296-14.911C41.954 5.19 35.919 1.203 29.328 1.203c-6.598 0-12.628 3.993-15.124 10.228a15.845 15.845 0 001.902 15.216l4.172 5.64-.043.032-18.108 18.09 11.027 11.015L28.35 43.201l13.476 18.223 11.028-11.016-15.875-17.744zM21.988 15.88c4.045-4.028 10.58-4.041 14.64-.039-.85 1.024-.96 1.03-7.714 7.808-6.74-7.545-6.463-7.201-6.926-7.769z" _fill="#F3F4F5"/>'
  }
})
