/* eslint-disable */
require('./ic_add-image')
require('./ic_adhesion')
require('./ic_admin')
require('./ic_aid')
require('./ic_allUser')
require('./ic_arrow-right')
require('./ic_article')
require('./ic_briefcase')
require('./ic_carbon_time')
require('./ic_chat')
require('./ic_clarity_phone-handset-solid')
require('./ic_close')
require('./ic_comment')
require('./ic_condom')
require('./ic_dashboard')
require('./ic_delete_article')
require('./ic_delete-red')
require('./ic_down')
require('./ic_dropdown')
require('./ic_eye-close')
require('./ic_eye-open')
require('./ic_fe_lock')
require('./ic_feather_check-circle')
require('./ic_feather-trash')
require('./ic_forum_menu')
require('./ic_forum')
require('./ic_go-back')
require('./ic_hospital')
require('./ic_location')
require('./ic_lock-closed')
require('./ic_lock-password-fill')
require('./ic_login-page')
require('./ic_logo')
require('./ic_mail')
require('./ic_more-info')
require('./ic_notif')
require('./ic_page-left')
require('./ic_page-right')
require('./ic_phone')
require('./ic_pin')
require('./ic_point-ssr')
require('./ic_preso')
require('./ic_pro')
require('./ic_profession')
require('./ic_ps-justice')
require('./ic_quiz')
require('./ic_quizzz')
require('./ic_search')
require('./ic_select-answer')
require('./ic_select-not-answer')
require('./ic_select-right-answer')
require('./ic_select-wrong-answer')
require('./ic_sexual_gender')
require('./ic_sida')
require('./ic_solid_transgender')
require('./ic_transgender')
require('./ic_up')
require('./ic_update_article')
require('./ic_update')
require('./ic_user')
require('./ic_users')
require('./ic-delete-black')
