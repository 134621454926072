/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_chat': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<path pid="0" d="M0 2.75A2.75 2.75 0 012.75 0h16.5A2.75 2.75 0 0122 2.75v11a2.75 2.75 0 01-2.75 2.75h-3.438a1.374 1.374 0 00-1.1.55L12.1 20.533a1.377 1.377 0 01-2.2 0L7.287 17.05a1.374 1.374 0 00-1.1-.55H2.75A2.75 2.75 0 010 13.75v-11zm6.875 5.5a1.375 1.375 0 10-2.75 0 1.375 1.375 0 002.75 0zm5.5 0a1.375 1.375 0 10-2.75 0 1.375 1.375 0 002.75 0zM16.5 9.625a1.375 1.375 0 100-2.75 1.375 1.375 0 000 2.75z" _fill="#0B6B75"/>'
  }
})
