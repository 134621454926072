/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_profession': {
    width: 50,
    height: 50,
    viewBox: '0 0 50 50',
    data: '<circle pid="0" opacity=".1" cx="25" cy="25" r="25" _fill="#24BC73"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M31.667 18a4 4 0 00-4-4h-5.334a4 4 0 00-4 4H17a4 4 0 00-4 4v10.667a4 4 0 004 4h16a4 4 0 004-4V22a4 4 0 00-4-4h-1.333zm-4-1.333h-5.334A1.333 1.333 0 0021 18h8a1.333 1.333 0 00-1.333-1.333zm-10.667 4h16A1.333 1.333 0 0134.333 22v10.667A1.333 1.333 0 0133 34H17a1.333 1.333 0 01-1.333-1.333V22A1.333 1.333 0 0117 20.667z" _fill="#24BC73"/>'
  }
})
