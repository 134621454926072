/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_users': {
    width: 34,
    height: 34,
    viewBox: '0 0 34 34',
    data: '<circle pid="0" opacity=".1" cx="17" cy="17" r="17" _fill="#fff"/><path pid="1" d="M16.2 13.8a2.4 2.4 0 11-4.8 0 2.4 2.4 0 014.8 0zM22.6 13.8a2.4 2.4 0 11-4.801 0 2.4 2.4 0 014.801 0zM19.344 22.6c.037-.262.056-.528.056-.8a5.576 5.576 0 00-1.2-3.464 4 4 0 016 3.464v.8h-4.856zM13.8 17.8a4 4 0 014 4v.8h-8v-.8a4 4 0 014-4z" _fill="#fff"/>'
  }
})
