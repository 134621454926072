/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_up': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M2.869 11.313H13.13c.308 0 .48-.325.29-.547l-5.132-5.95a.39.39 0 00-.578 0l-5.132 5.95c-.19.222-.018.546.29.546z" _fill="#595B62"/>'
  }
})
