/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_pro': {
    width: 50,
    height: 50,
    viewBox: '0 0 50 50',
    data: '<circle pid="0" opacity=".1" cx="25" cy="25" r="25" _fill="#FF9A03"/><path pid="1" d="M32 15H19c-1.206 0-3 .799-3 3v14c0 2.201 1.794 3 3 3h15v-2H19.012C18.55 32.988 18 32.805 18 32c0-.101.009-.191.024-.273.112-.575.583-.717.987-.727H33c.018 0 .031-.009.049-.01H34V17c0-1.103-.897-2-2-2zm0 14H18V18c0-.806.55-.988 1-1h7v7l2-1 2 1v-7h2v12z" _fill="#FF9A03"/>'
  }
})
