/*
App
Created at 09/04/2021 11:44
Author: Richard AHOUNOU
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import pathResolver from '../plugins/loader'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: pathResolver('login', 'index'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/ressetPassword',
    name: 'ResetPassword',
    component: pathResolver('reset-password', 'index'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/cgu',
    name: 'Cgu',
    component: pathResolver('cgu', 'index'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/policy',
    name: 'Policy',
    component: pathResolver('policy', 'index'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/',
    name: 'About',
    component: pathResolver('about', 'index'),
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: pathResolver('dashboard', 'index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/users',
        name: 'Users',
        component: pathResolver('users', 'index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/membership',
        name: 'Membership',
        component: pathResolver('membership', 'index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/points',
        name: 'Points',
        component: pathResolver('points', 'index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/article',
        name: 'Article',
        component: pathResolver('article', 'index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/quiz',
        name: 'Quiz',
        component: pathResolver('quiz', 'index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/forum',
        name: 'Forum',
        component: pathResolver('forum', 'index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/admin',
        name: 'Admin',
        component: pathResolver('admin', 'index'),
        meta: {
          requireAuth: true
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // retourner la position désirée
    if (savedPosition) {
      return savedPosition
    } else {
      if (to.hash) {
        return { selector: to.hash }
      } else {
        return { x: 0, y: 0 }
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth)) {
    // console.log(store.getters.loginToken)
    if (store.getters.loginToken.length > 1) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
