/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_go-back': {
    width: 26,
    height: 16,
    viewBox: '0 0 26 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M25.25 8a.875.875 0 00-.875-.875H3.737L9.245 1.62A.876.876 0 008.005.38l-7 7a.875.875 0 000 1.24l7 7a.875.875 0 001.43-.955.876.876 0 00-.19-.284L3.737 8.874h20.638A.875.875 0 0025.25 8z" _fill="#000"/>'
  }
})
