/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_preso': {
    width: 41,
    height: 40,
    viewBox: '0 0 41 40',
    data: '<path pid="0" d="M40.399 14.062c-.812-3.22-3.227-5.413-6.625-6.018-4.422-.79-9.198.5-12.99 1.813-1.365-2.186-2.335-4.338-2.81-5.474A6.48 6.48 0 0016.8 2.51C13.868-.705 11.039-.141 9.613.48c-1.311.571-2.409 1.618-3.09 2.947-1.046 2.039-.848 4.16-.497 5.58.202.818.594 1.601 1.199 2.393a15.338 15.338 0 012.355 4.43c-2.703 2.058-4.983 4.367-6.617 6.699C.395 26.199-.549 29.93.31 33.037c.654 2.37 2.287 4.2 4.723 5.292A18.96 18.96 0 0012.87 40c7.867 0 14.74-4.617 17.836-7.072 8.88-7.042 10.83-14.362 9.694-18.866zm-13.546 14.03c-6.493 4.954-12.948 6.8-17.269 4.937-1.758-.758-2.892-1.931-3.277-3.392-.499-1.889.243-4.215 2.144-6.729a22.473 22.473 0 011.959-2.234c.098 3.847-1.038 6.773-1.053 6.813a.804.804 0 001.492.597c.095-.238 2.304-5.874.44-12.176v-.005l-.006-.015-.013-.05c-.003-.008-.01-.013-.012-.021a16.931 16.931 0 00-2.755-5.392c-.469-.614-.769-1.203-.916-1.803-.286-1.154-.454-2.865.365-4.462.513-1 1.331-1.783 2.303-2.207a4.024 4.024 0 011.62-.355c1.274 0 2.525.667 3.737 1.995.356.39.652.865.879 1.409.505 1.208 1.552 3.516 3.023 5.846.004.008.004.016.008.023l.14.211c.039.061.074.122.114.183l.032.042c2.856 4.35 5.94 6.61 9.014 6.61.447 0 .894-.048 1.339-.143a.804.804 0 00-.338-1.572c-2.504.537-4.8-1.03-6.66-3.118 2.866-.802 5.517-.954 7.723-.435 2.356.557 3.725 1.651 4.066 3.255.67 3.14-2.51 7.923-8.099 12.188z" _fill="#13A8C0"/>'
  }
})
