/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_update': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M3.643 16c.05 0 .1-.005.15-.012l4.204-.738a.245.245 0 00.133-.07L18.727 4.582a.248.248 0 000-.352L14.572.072A.247.247 0 0014.396 0a.247.247 0 00-.178.072L3.62 10.67a.254.254 0 00-.07.133l-.737 4.204a.838.838 0 00.234.745c.165.16.373.248.595.248zm1.684-4.36l9.068-9.065 1.832 1.832-9.067 9.066-2.223.392.39-2.225zM19.2 18.1H.8a.8.8 0 00-.8.8v.9c0 .11.09.2.2.2h19.6a.2.2 0 00.2-.2v-.9a.8.8 0 00-.8-.8z" _fill="#096CB2"/>'
  }
})
