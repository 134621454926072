/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_carbon_time': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8 15A7 7 0 118 1a7 7 0 010 14zM8 2a6 6 0 100 12A6 6 0 008 2z" _fill="#909090"/><path pid="1" d="M10.295 11L7.5 8.205V3.5h1v4.29l2.5 2.505-.705.705z" _fill="#909090"/>'
  }
})
