/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_select-wrong-answer': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<path pid="0" d="M11 1.833a9.167 9.167 0 100 18.334 9.167 9.167 0 000-18.334zm0 16.5a7.333 7.333 0 110-14.666 7.333 7.333 0 010 14.666z" _fill="#DE1D1D"/><path pid="1" d="M13.484 8.516a.917.917 0 00-1.302 0L11 9.708 9.817 8.516a.92.92 0 10-1.301 1.302L9.707 11l-1.191 1.182a.917.917 0 000 1.302.916.916 0 001.301 0L11 12.293l1.182 1.191a.917.917 0 001.302 0 .918.918 0 000-1.302L12.292 11l1.192-1.182a.918.918 0 000-1.302z" _fill="#DE1D1D"/>'
  }
})
